<template>
  <div class="module" :class="{ 'has-ticket': hasTicket }" v-if="!isProExpertEvent">
    <div class="container">
      <div v-if="hasTicket" class="has-text-centered">
        <p class="title primary-text-color block is-size-4 has-text-weight-bold">{{ $t("ticket.already_bought") }}</p>
        <router-link class="button is-primary" :to="{ name: Constants.ROUTE_MY_PROFILE }" v-if="!isTargiSpecjalEvent">
          {{ $t("ticket.move_to_your_profile") }} &raquo;
        </router-link>
      </div>
      <div class="" v-else>
        <separator class="separator is-primary" v-if="separatorVisible"></separator>

        <div class="has-text-accent has-text-link is-size-6 has-text-weight-bold has-text-centered">
          {{ moduleSubtitle }}
        </div>
        <div class="has-text-centered is-size-2 has-text-weight-bold">
          {{ moduleTitle }}
        </div>
        <div class="columns is-centered has-tickets" :class="{ 'is-3': tickets.length === 3, 'equal-heights': equalHeights }">
          <div v-for="ticket in tickets" :key="ticket.id" class="column is-narrow">
            <event-ticket-view
              :ticket="ticket"
              :custom-description="getCustomDescription(ticket.id)"
              :disabled="hasTicket"
              :pretax-price-visible="pretaxPriceVisible"
              :pretax-price-highlighted="pretaxPriceHighlighted"
              :event-tickets-vat="eventTicketsVat"
              :ticket-view-type="ticketViewType"
              @click="onBuyTicketClick"
            ></event-ticket-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import EventTicketView from "@/web/components/eventtickets/EventTicketView";
import Constants from "@/web/constants";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import Separator from "@/web/components/shared/Separator";

export default {
  name: "EventTicketsComponent",
  components: { EventTicketView, Separator },
  props: ["moduleData"],
  mixins: [LpConfigMixin],
  computed: {
    ...mapState(["eventId"]),
    ...mapGetters(["lpSettings", "eventTicketsVat"]),
    ...mapGetters("eventTickets", ["getAllTicketsFromModule", "getAllTickets"]),
    ...mapGetters("auth", ["isJoined"]),
    ...mapGetters("userTickets", ["ticket"]),

    Constants: () => Constants,

    isProExpertEvent() {
      return this.eventId === 1419;
    },

    isPismEvent() {
      return this.eventId === 1312;
    },

    isKnm2024() {
      return this.eventId === 1441;
    },

    isAiBaConference() {
      return this.eventId === 1559;
    },

    isTargiSpecjalEvent() {
      return this.eventId === 1575;
    },

    isIgrzyskaWolnosciEvent() {
      return this.eventId === 1468;
    },

    moduleFields: () => LpConfigConstants.EVENT_TICKETS_MODULE_FIELDS,

    paymentConfig() {
      return this.getFieldValueOrDefault(this.lpSettings, LpConfigConstants.ROOT_FIELDS.PAYMENT, {});
    },

    tickets() {
      let ticketIds = this.moduleData[this.moduleFields.TICKET_IDS];
      if (window.location.host.includes("architekt.lignumsoft")) {
        return this.getAllTickets.filter(ticket => ticket.id === 494 || ticket.id === 495);
      } else if (this.isKnm2024) {
        const ticketIdsToHide = [591, 663, 662, 539];
        return this.getAllTickets.filter(ticket => !ticketIdsToHide.includes(ticket.id));
      } else if (this.isIgrzyskaWolnosciEvent) {
        const ticketIdsToHide = [798, 799];
        return this.getAllTickets.filter(ticket => !ticketIdsToHide.includes(ticket.id));
      } else if (this.moduleData.ticket_ids.length === 0) {
        return this.getAllTickets;
      } else {
        return this.getAllTicketsFromModule(this.moduleData[LpConfigConstants.COMMON_MODULE_FIELDS.ORDER])
          .filter(ticket => ticketIds.includes(ticket.id))
          .sort((a, b) => {
            return ticketIds.indexOf(a.id) > ticketIds.indexOf(b.id) ? 1 : -1;
          });
      }
    },

    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    hasTicket() {
      return !!this.ticket;
    },

    equalHeights() {
      return !!this.moduleData[this.moduleFields.EQUAL_HEIGHTS];
    },

    pretaxPriceVisible() {
      return this.moduleData[this.moduleFields.PRETAX_PRICE_VISIBLE];
    },

    customDescriptions() {
      return this.moduleData[this.moduleFields.CUSTOM_DESCRIPTIONS];
    },

    pretaxPriceHighlighted() {
      return this.pretaxPriceVisible && this.moduleData[this.moduleFields.PRETAX_PRICE_HIGHLIGHTED];
    },

    ticketViewType() {
      return this.getFieldValueOrDefault(
        this.moduleData,
        this.moduleFields.TICKET_VIEW_TYPE,
        LpConfigConstants.EVENT_TICKET_VIEW_TYPES.CINEMA
      );
    },

    moduleTitle() {
      return this.getCustomTranslation(this.moduleData, LpConfigConstants.COMMON_MODULE_FIELDS.TITLE);
    },

    moduleSubtitle() {
      return this.getCustomTranslation(this.moduleData, LpConfigConstants.EVENT_TICKETS_MODULE_FIELDS.SUBTITLE);
    },
  },
  methods: {
    onBuyTicketClick(ticketId) {
      const buyTicketRoute = { name: Constants.ROUTE_BUY_TICKET, params: { ticketId } };
      this.$router.push(buyTicketRoute);
    },

    getCustomDescription(ticketId) {
      return this.customDescriptions && this.customDescriptions[ticketId];
    },
  },
};
</script>

<style lang="scss" scoped>
// Workaroud for hiding ticket module siblings
.module.has-ticket + .module.has-ticket {
  display: none;
}

.custom-columns {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  margin: 0 auto;
  row-gap: 40px;
}

.custom-column {
  width: 50%;
  @media (max-width: 672px) {
    width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .columns.is-3 {
    padding: 80px 0;

    &.equal-heights {
      padding: 40px 0;
    }

    &:not(.equal-heights) {
      .column:nth-child(2) {
        margin-top: -80px;
        margin-bottom: -80px;
      }
    }
  }
}
</style>
