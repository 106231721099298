<template>
  <div>
    <div class="faq-question-container unselectable" :class="{ 'is-active': isActive }" @click="isActive = !isActive">
      <div class="faq-question" v-html="question"></div>
      <div class="faq-question-icon" :class="{ 'is-active': isActive }">
        <arrow-down class="svg-icon"></arrow-down>
      </div>
    </div>
    <div class="faq-answer" v-if="isActive" v-html="answer"></div>
  </div>
</template>

<script>
import ArrowDown from "@/assets/arrow-down.svg";

export default {
  name: "FaqQuestion",

  components: {
    ArrowDown,
  },

  props: {
    question: {
      type: String,
      required: true,
    },

    answer: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isActive: false,
    };
  },
};
</script>

<style scoped></style>
