<template>
  <div class="event-timer" :class="{ 'is-pism': isPismEvent || isDolbyEvent }">
    <div class="columns is-variable is-2-mobile is-6-tablet is-mobile is-centered is-vcentered my-0 py-1">
      <div class="column is-narrow py-0">
        <timer-label>
          <span slot="title">{{ daysLeft }}</span>
          <span slot="subtitle">{{ $t("event_info.days") }}</span>
        </timer-label>
      </div>
      <timer-dots-icon class="timer-dots"></timer-dots-icon>
      <div class="column is-narrow py-0">
        <timer-label>
          <span slot="title">{{ hoursLeft }}</span>
          <span slot="subtitle">{{ $t("event_info.hours") }}</span>
        </timer-label>
      </div>
      <timer-dots-icon class="timer-dots"></timer-dots-icon>
      <div class="column is-narrow py-0">
        <timer-label>
          <span slot="title">{{ minutesLeft }}</span>
          <span slot="subtitle">{{ $t("event_info.minutes") }}</span>
        </timer-label>
      </div>
      <timer-dots-icon class="timer-dots"></timer-dots-icon>
      <div class="column is-narrow py-0">
        <timer-label>
          <span slot="title">{{ secondsLeft }}</span>
          <span slot="subtitle">{{ $t("event_info.seconds") }}</span>
        </timer-label>
      </div>
    </div>
    <div class="pism-timer-container" v-if="isPismEvent || isDolbyEvent">
      <div class="pism-timer-image">
        <img class="svg-icon" :src="pismGoldenTicketImageUrl" />
      </div>
      <div class="pism-timer-description-container">
        <div class="pism-timer-description-row" :class="{ 'mt-1': isDolbyEvent }">
          <pism-icon v-if="isPismEvent" class="pism-timer-icon"></pism-icon>
          <default-separator v-else-if="isDolbyEvent" class="pism-timer-icon has-fill-primary"></default-separator>
          <span v-if="isPismEvent">{{ $t("custom.pism_2023_timer_text_0") }}</span>
          <span class="has-text-weight-medium" v-else-if="isDolbyEvent">{{ $t("custom.dolby_timer_text_0") }}</span>
        </div>
        <div class="pism-timer-description-row">
          <pism-icon v-if="isPismEvent" class="pism-timer-icon"></pism-icon>
          <default-separator v-else-if="isDolbyEvent" class="pism-timer-icon has-fill-primary"></default-separator>
          <span v-if="isPismEvent">{{ $t("custom.pism_2023_timer_text_1") }}</span>
          <span style="margin-left: 3px" class="has-text-weight-medium" v-else-if="isDolbyEvent">{{
            $t("custom.dolby_timer_text_1")
          }}</span>
        </div>

        <button class="button is-primary pism-timer-button" v-if="!ticket && !isDolbyEvent" @click="onRegisterButtonClick">
          <template v-if="isPismEvent">
            {{ $t("custom.pism_2023_register_for_free") }}
          </template>
          <template v-else>
            {{ $t("custom.dolby_register_button") }}
          </template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TimerDotsIcon from "@/assets/icon_timer_dots.svg";
import TimerLabel from "@/web/components/eventinfo/TimerLabel";
import dayjs from "@/shared/utils/day";
import PismGoldenTicket from "@/assets/custom/pism/pism_golden_ticket.png";
import PismIcon from "@/assets/custom/pism/pism_icon.svg";
import DefaultSeparator from "@/assets/icons/point.svg";
import { mapGetters, mapState } from "vuex";
import Constants from "@/web/constants";

export default {
  name: "EventTimeCounterView",
  components: {
    TimerDotsIcon,
    TimerLabel,
    PismIcon,
    DefaultSeparator,
  },
  props: ["date"],

  data() {
    return {
      timerRef: null,
      currentTime: dayjs(),
    };
  },

  mounted() {
    this.timerRef = setInterval(() => (this.currentTime = dayjs()), 1000);
  },

  destroyed() {
    clearInterval(this.timerRef);
  },

  computed: {
    ...mapState(["eventId"]),
    ...mapGetters("userTickets", ["ticket"]),

    isPismEvent() {
      return this.eventId === 1312;
    },

    isDolbyEvent() {
      return this.eventId === 1318;
    },

    pismGoldenTicketImageUrl() {
      return PismGoldenTicket;
    },

    isVisible() {
      return dayjs(this.date) > dayjs();
    },

    startTime() {
      return dayjs(this.date);
    },

    daysLeft() {
      const now = this.currentTime;
      const diff = this.startTime.diff(now, "days");
      return Math.max(0, diff);
    },

    hoursLeft() {
      const now = this.currentTime;
      const diff = this.startTime.diff(now, "hours") % 24;
      return Math.max(0, diff);
    },

    minutesLeft() {
      const now = this.currentTime;
      const diff = this.startTime.diff(now, "minutes") % 60;
      return Math.max(0, diff);
    },

    secondsLeft() {
      const now = this.currentTime;
      const diff = this.startTime.diff(now, "seconds") % 60;
      return Math.max(0, diff);
    },
  },

  methods: {
    onRegisterButtonClick() {
      this.$router.push({ name: Constants.ROUTE_BUY_TICKET_NO_SELECTION });
    },
  },
};
</script>
