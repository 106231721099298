<template>
  <article class="module">
    <div>
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <h2 class="title primary-text-color is-2 has-text-centered mb-4">
        {{ title }}
      </h2>
      <div class="has-fullwidth is-flex is-flex-direction-row is-justify-content-flex-end px-3 my-1">
        <div
          class="button full-screen-disabled-button full-screen-disabled has-text-centered"
          @click="toggleFullScreen"
          v-if="fullscreenEnabled"
        >
          <div class="mr-1">Full Screen</div>
          <full-screen-enter class="svg-icon"></full-screen-enter>
        </div>
      </div>
      <b-tabs v-model="tabIndex" position="is-centered" expanded v-if="tabsEnabled">
        <b-tab-item :label="$t(tabTitle)" v-for="(tabTitle, index) in tabTitles" :key="`tabbed-iframe-${index}`"></b-tab-item>
      </b-tabs>
      <fullscreen ref="fullscreen" @change="fullscreenChange">
        <div class="is-relative" :class="fullScreenContainerClasses">
          <div class="columns is-gapless">
            <div class="column" :class="iframeClass">
              <div class="iframe" v-html="html">
                <!-- Content provided by the module data -->
              </div>
            </div>
            <!--            <div class="column is-two-fifths" v-if="fullScreen">-->
            <!--              <feed-wall-bubble :is-active="true" :bubble="feedWallBubble" ref="bubble"></feed-wall-bubble>-->
            <!--            </div>-->
          </div>
          <div class="rating-modal-container" v-if="fullScreen && rating">
            <div class="rating-modal">
              <i-love-marketing-rating-modal :component-id="rating.componentId" :quiz-id="rating.quizId"></i-love-marketing-rating-modal>
            </div>
          </div>

          <div class="button full-screen-button full-screen-enabled" @click="toggleFullScreen" v-if="fullScreen">
            <div class="mr-1">Exit</div>
            <full-screen-exit class="svg-icon"></full-screen-exit>
          </div>
        </div>
      </fullscreen>
    </div>
  </article>
</template>

<script>
import Separator from "@/web/components/shared/Separator";
import FullScreenEnter from "@/assets/iframe/fullscreen_enter.svg";
import FullScreenExit from "@/assets/iframe/fullscreen_exit.svg";
// import FeedWallBubble from "@/web/components/sidemenu/bubbles/FeedWallBubble";
import { mapGetters, mapState } from "vuex";
import ILoveMarketingRatingModal from "@/web/components/custom/ILoveMarketingRatingModal";
import { getComponentId, getQuizId, isQuizzesComponent } from "@/shared/utils/deeplink-utils";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "IFrameComponent",
  mixins: [LpConfigMixin],
  components: {
    ILoveMarketingRatingModal,
    // FeedWallBubble,
    Separator,
    FullScreenEnter,
    FullScreenExit,
  },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      scriptRef: null,
      fullScreen: false,
      rating: null,
      tabIndex: 0,
    };
  },

  mounted() {
    this._scriptRef = null;
    this.mountScriptElement();
  },

  beforeDestroy() {
    this.disposeScriptElement();
  },

  methods: {
    mountScriptElement() {
      this.disposeScriptElement();
      if (this.javascript) {
        this._scriptRef = document.createElement("script");
        this._scriptRef.type = "text/javascript";
        this._scriptRef.innerHTML = this.javascript;
        document.body.appendChild(this._scriptRef);
      }
    },

    disposeScriptElement() {
      if (this._scriptRef) {
        this._scriptRef.remove();
        this._scriptRef = null;
      }
    },

    toggleFullScreen() {
      this.$refs.fullscreen.toggle();
      this.fullScreen = !this.fullScreen;
    },

    fullscreenChange(fullscreen) {
      this.fullScreen = fullscreen;
    },
  },

  computed: {
    ...mapState("notifications", ["standardNotification"]),
    ...mapState(["eventId"]),
    ...mapGetters(["agendaRatingPopupConfig"]),

    iFrameModuleFields: () => LpConfigConstants.IFRAME_MODULE_FIELDS,
    tabFields: () => LpConfigConstants.IFRAME_TAB_FIELDS,
    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE);
    },

    tabsEnabled() {
      return this.getFieldValueOrDefault(this.moduleData, this.iFrameModuleFields.TABS_ENABLED, false);
    },

    tabTitles() {
      return (
        this.moduleData[this.iFrameModuleFields.TABS_DATA] &&
        this.moduleData[this.iFrameModuleFields.TABS_DATA].map(tab => this.getCustomTranslation(tab, this.tabFields.TITLE))
      );
    },

    javascript() {
      return this.getCustomTranslation(this.moduleData[this.iFrameModuleFields.TABS_DATA][this.tabIndex], this.tabFields.SCRIPT);
    },

    html() {
      return this.getCustomTranslation(this.moduleData[this.iFrameModuleFields.TABS_DATA][this.tabIndex], this.tabFields.HTML);
    },

    separatorVisible() {
      return !this.moduleData[this.commonModuleFields.SEPARATOR_DISABLED];
    },

    fullscreenEnabled() {
      return !!this.moduleData[this.iFrameModuleFields.FULL_SCREEN_ENABLED];
    },

    iframeClass() {
      if (this.fullScreen) {
        return "is-three-fifth";
      } else {
        return "";
      }
    },

    fullScreenContainerClasses() {
      if (this.fullScreen) {
        return {
          "full-screen-container": true,
        };
      } else {
        return {};
      }
    },

    // feedWallBubble() {
    //   return {
    //     type: Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE,
    //     feedWallChannelId: 641,
    //     isFresh: true,
    //   };
    // },
  },

  watch: {
    // Re-create script element on locale/tab change
    javascript() {
      this.$nextTick(() => this.mountScriptElement());
    },

    standardNotification: {
      handler: function (newVal) {
        if (newVal && this.fullScreen) {
          if (this.eventId === this.agendaRatingPopupConfig[LpConfigConstants.FEATURES_FIELDS.AGENDA_RATING_POPUP_FIELDS.EVENT_ID]) {
            if (newVal.dl) {
              const deepLink = newVal.dl;
              if (isQuizzesComponent(deepLink)) {
                const componentId = getComponentId(deepLink);
                if (
                  componentId ===
                  this.agendaRatingPopupConfig[LpConfigConstants.FEATURES_FIELDS.AGENDA_RATING_POPUP_FIELDS.QUIZ_COMPONENT_ID]
                ) {
                  const quizId = getQuizId(deepLink);
                  this.rating = {
                    componentId: componentId,
                    quizId: quizId,
                  };
                }
              }
            }
          }
        } else if (newVal === null) {
          this.rating = null;
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.fullscreen {
  overflow-x: hidden;
}

.full-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.full-screen-button {
  padding-left: 10px;
  padding-right: 10px;

  &.full-screen-enabled {
    position: absolute;
    top: 3%;
    right: 0.5%;
  }

  &.full-screen-disabled {
    margin-left: auto;
  }
}

.rating-modal-container {
  background: rgba(black, 0.5);
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  align-items: center;
  justify-content: center;

  .rating-modal {
  }
}
</style>
