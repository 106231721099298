<template>
  <article class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <h2 class="title primary-text-color is-2 has-text-centered mb-4">
        {{ title }}
      </h2>
      <!-- https://www.youtube.com/watch?v=OoDwXaEvtnM -->

      <div class="ped-edition">
        <h3 class="ped-edition-title">
          {{ $t("custom.ped_prev_edition_p1") }} <span class="bold">{{ $t("custom.ped_prev_edition_bolded") }}</span>
          {{ $t("custom.ped_prev_edition_p2") }}
        </h3>
        <div class="ped-edition-video">
          <iframe
            src="https://www.youtube.com/embed/cGA--l7tvG0?si=27Q1hR1eS_4JKrwL"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import Separator from "@/web/components/shared/Separator";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "PedPreviousEdition",
  mixins: [LpConfigMixin],
  components: { Separator },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE);
    },

    pageSize() {
      return this.getFieldValueOrDefault(this.moduleData, this.agendaModuleFields.PAGE_SIZE, 8);
    },

    ticketViewType() {
      return LpConfigConstants.EVENT_TICKET_VIEW_TYPES.CINEMA;
    },
  },
};
</script>

<style scoped>
.container {
  padding-top: 80px;
  padding-bottom: 80px;
}

.bold {
  font-weight: 700;
}

.ped-edition {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ped-edition-title {
  font-size: 48px;
  font-weight: 400;
  line-height: 130%;
  color: #f2f2f2;
  max-width: 460px;
}

.ped-edition-video {
  width: 620px;
  height: 380px;
}

.ped-edition-video iframe {
  width: 100%;
  height: 100%;
}

@media (max-width: 1396px) {
  .ped-edition-title {
    font-size: 32px;
    max-width: calc(100% - 420px);
    margin-right: 20px;
  }

  .ped-edition-video {
    width: 420px;
    height: 220px;
  }
}

@media (max-width: 726px) {
  .ped-edition {
    flex-wrap: wrap;
  }

  .ped-edition-title {
    max-width: 100%;
    width: 100%;
    margin-bottom: 40px;
  }

  .ped-edition-video {
    width: 100%;
    height: 360px;
  }
}

@media (max-width: 622px) {
  .ped-edition-video {
    height: 280px;
  }
}

@media (max-width: 545px) {
  .ped-edition-video {
    height: 180px;
  }
}
</style>
