<template>
  <div class="carousel-pusher" :class="{ 'has-arrows': arrowsVisible }">
    <div class="columns is-gapless is-vcentered is-mobile">
      <div class="column is-narrow">
        <div class="carousel-arrow" v-if="arrowsVisible" @click="prev">
          <left-arrow-icon></left-arrow-icon>
        </div>
      </div>

      <div class="column">
        <div class="carousel-wrapper">
          <b-carousel-list
            :items-to-show="itemsPerPage"
            :arrow="false"
            :repeat="true"
            :data="partners"
            v-model="selectedIndex"
            class="is-shadowless"
          >
            <template slot="item" slot-scope="partner">
              <partner-view
                :partner="partner"
                :level="level"
                :component-id="componentId"
                :bg-card-color="bgCardColor"
                :open-external-link="openExternalLink"
                :is-borderless="false"
              ></partner-view>
            </template>
          </b-carousel-list>
        </div>
      </div>

      <div class="column is-narrow">
        <div class="carousel-arrow" v-if="arrowsVisible" @click="next">
          <right-arrow-icon></right-arrow-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftArrowIcon from "@/assets/icon_carousel_arrow_left.svg";
import RightArrowIcon from "@/assets/icon_carousel_arrow_right.svg";
import PartnerView from "@/web/components/partners/PartnerView";

export default {
  name: "PartnersCarouselView",

  components: {
    LeftArrowIcon,
    RightArrowIcon,
    PartnerView,
  },

  props: ["category", "componentId", "isMobile", "openExternalLink", "bgCardColor"],

  data() {
    return {
      selectedIndex: 0,
    };
  },

  methods: {
    next() {
      this.selectedIndex++;
    },

    prev() {
      this.selectedIndex--;
    },
  },

  computed: {
    level() {
      return this.category.level;
    },

    partners() {
      let partners = this.category.partners;
      return partners.sort((a, b) => a.order - b.order);
    },

    itemsPerPage() {
      if (this.isMobile) {
        return 2;
      } else {
        const index = this.level - 1;
        return [4, 3, 2][index] || 4;
      }
    },

    arrowsVisible() {
      return this.itemsPerPage < this.partners.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-pusher:not(.has-arrows) {
  margin-right: 0;
  margin-left: 0;

  // Align partner logos to the center
  // Only when the carousel is arrow-less
  ::v-deep .carousel-slides {
    justify-content: center;
  }
}

.column {
  min-width: 0;
}
</style>
