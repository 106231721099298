<template>
  <article class="module">
    <div class="ped-location">
      <div class="container">
        <separator class="separator is-primary" v-if="separatorVisible"></separator>
        <h2 class="title primary-text-color is-2 has-text-centered mb-4">
          {{ title }}
        </h2>

        <div class="ped-location-content">
          <h2 class="ped-location-title">
            {{ $t("custom.ped_location_header") }} <span class="bold">{{ $t("custom.ped_location_header_bolded") }}</span>
          </h2>
          <h3 class="ped-location-subtitle">{{ $t("custom.ped_location_subheader") }}</h3>

          <div class="ped-location-bottom">
            <img src="@/assets/custom/ped/location-section/ckf_13_logo.png" alt="" class="ped-location-img" />
            <div class="ped-location-desc">
              <p>{{ $t("custom.ped_location_desc_p1") }}</p>
              <p>{{ $t("custom.ped_location_desc_p2") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import Separator from "@/web/components/shared/Separator";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "PedLocationComponent",
  mixins: [LpConfigMixin],
  components: { Separator },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE);
    },

    pageSize() {
      return this.getFieldValueOrDefault(this.moduleData, this.agendaModuleFields.PAGE_SIZE, 8);
    },

    ticketViewType() {
      return LpConfigConstants.EVENT_TICKET_VIEW_TYPES.CINEMA;
    },
  },
};
</script>

<style scoped>
.bold {
  font-weight: 700;
}

.container {
  padding-top: 140px;
}

.ped-location {
  background-image: url(~@/assets/custom/ped/location-section/bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ped-location-title {
  font-size: 70px;
  font-weight: 400;
  color: #f2f2f2;
  line-height: 120%;
  margin-bottom: 40px;
}

.ped-location-subtitle {
  font-size: 44px;
  color: #fff;
  letter-spacing: 27.72px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 60px;
}

.ped-location-bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.ped-location-img {
  width: 436px;
  height: auto;
}

.ped-location-desc {
  width: calc(90% - 436px);
}

.ped-location-desc p {
  color: #fff;
  font-size: 20px;
  line-height: 120%;
  font-weight: 400;
}

.ped-location-desc p:nth-child(1) {
  margin-bottom: 20px;
}

@media (max-width: 968px) {
  .ped-location-img {
    width: 320px;
    height: auto;
  }

  .ped-location-desc {
    width: calc(98% - 320px);
  }

  .ped-location-desc p {
    font-size: 16px;
  }

  .ped-location-title {
    font-size: 54px;
  }

  .ped-location-subtitle {
    font-size: 36px;
    letter-spacing: 20px;
  }
}

@media (max-width: 656px) {
  .ped-location-bottom {
    padding-bottom: 140px;
  }

  .ped-location-img {
    width: 180px;
    height: auto;
  }

  .ped-location-desc {
    width: calc(98% - 180px);
  }
}

@media (max-width: 486px) {
  .ped-location-bottom {
    flex-wrap: wrap;
    justify-content: center;
  }
  .ped-location-img {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  .ped-location-desc {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 446px) {
  .ped-location-title {
    font-size: 38px;
  }

  .ped-location-subtitle {
    font-size: 26px;
    letter-spacing: 14px;
  }
}
</style>
