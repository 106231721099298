<template>
  <article class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <h2 class="title primary-text-color is-2 has-text-centered mb-2" v-if="title">
        {{ title }}
      </h2>
      <div class="box is-radius-large is-fullwidth">
        <ruptor class="ruptor-icon"></ruptor>
        <p class="mt-3 has-text-centered has-text-weight-semibold description-text">
          {{ $t("custom.srk_registration_closed_title") }}
        </p>
        <p class="mt-3 has-text-centered has-text-weight-semibold description-text mb-2">
          {{ $t("custom.srk_registration_closed_subtitle") }}
        </p>
      </div>
      <div class="box is-radius-large mt-3" v-if="ticket">
        <accept class="accept-icon"></accept>
        <p class="has-text-centered has-text-weight-semibold description-text mt-3">
          {{ $t("custom.srk_module_description_already_bought") }}
        </p>
        <div class="mx-4">
          <div class="columns is-variable is-6 is-multiline mx-2 my-2 is-centered">
            <div class="column is-half" v-for="child in children" :key="child.id">
              <srk-child-summary :child-name-string="child.childName" :child-ticket-name="child.ticketName"></srk-child-summary>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import LpConfigConstants from "../../../../shared/constants/lp-config-constants";
import Constants from "@/web/constants";
import Separator from "@/web/components/shared/Separator";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import Accept from "@/assets/icons/accept_2.svg";
import RequestMixin from "@/shared/mixins/RequestMixin";
import SrkChildSummary from "@/web/components/custom/survivalracekids/SrkChildSummary";
import Ruptor from "@/assets/icons/rupor.svg";

export default {
  name: "SrkCustomModule",
  components: { Separator, Accept, Ruptor, SrkChildSummary },
  mixins: [LpConfigMixin, RequestMixin],

  data() {
    return {
      isMetaFetched: false,
      userMeta: null,
    };
  },

  requests: {
    async fetchMeta() {
      this.userMeta = await this.fetchUserMeta();
    },
  },

  computed: {
    ...mapState(["event"]),
    ...mapGetters("userTickets", ["ticket"]),
    ...mapGetters("auth", ["isAuthorized"]),

    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE, null);
    },

    children() {
      if (this.userMeta) {
        const meta = this.userMeta.find(meta => meta.name === "srk_children_json");
        const children = meta && meta.response && meta.response.length && JSON.parse(meta.response);
        return children || [];
      } else {
        return [];
      }
    },
  },

  methods: {
    ...mapActions("currentUser", ["fetchUserMeta"]),

    goToSrkRegister() {
      this.$router.push({ name: Constants.ROUTE_BUY_TICKET, params: { ticketId: "1" } });
    },
  },

  watch: {
    ticket: {
      immediate: true,
      handler: function (newValue) {
        if (newValue && !this.isMetaFetched) {
          this.isMetaFetched = true;
          this.fetchMeta();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.description-text {
  white-space: pre-wrap;
  color: var(--global-description-text-color);
  font-size: 24px;

  &.is-small {
    font-size: 18px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.accept-icon {
  width: 80px;
  height: 80px;
}

.ruptor-icon {
  width: 70px;
  height: 70px;
  /*fill: var(--primary-color);*/
  fill: var(--primary-color-text);
  background: var(--primary-color);
  border-radius: 50%;
  overflow: hidden;
  padding: 12px;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-color: white;
  box-shadow: 8px 8px 0px #f0f1f4;
}
</style>
