<template>
  <purchase-step :step="1">
    <template>
      <div class="ticket-second-step-form-container" v-for="ticketForm in ticketForms" :key="ticketForm.personalForm.email">
        <div class="title is-4 mb-1">
          {{ `${ticketForm.personalForm.firstName} ${ticketForm.personalForm.lastName}` }}
        </div>
        <div class="title is-6 mb-2">
          <template v-if="isDtsEvent">
            {{ `${getTicketById(ticketForm.ticketId).name}` }}
          </template>
          <template v-else-if="isMebleEvent || isProExpertEvent || isXEvent">
            {{ `${$t("custom.meble_invitation")}: ${getTicketById(ticketForm.ticketId).name}` }}
          </template>
          <template v-else-if="isPismEvent">
            {{ `${$t("custom.pism_2023_category")}: ${getTicketById(ticketForm.ticketId).name}` }}
          </template>
          <template v-else>
            {{ `${$t("payment.ticket")}: ${getTicketById(ticketForm.ticketId).name}` }}
          </template>
        </div>
        <custom-register-form
          ref="customRegisterForm"
          :current-locale="currentLocale"
          :form-data="ticketForm.customForm"
          :include-register-form="false"
          update-type="input"
          @input="updateAdditionalForm(ticketForm.personalForm.email, $event)"
        ></custom-register-form>
      </div>

      <div style="margin-top: -36px">
        <!-- Gdpr consent -->
        <div class="field" v-if="gdprVisible && isMebleEvent">
          <div class="control">
            <label class="checkbox is-size-7 mb-1">
              <checkbox class="checkbox-input" v-model="$v.gdprAccepted.$model"></checkbox>
              <i18n
                path="payment.gdpr_consent"
                tag="span"
                class="consent-text unselectable"
                :class="gdprConsentClass"
                @click="$v.gdprAccepted.$model = !$v.gdprAccepted.$model"
              >
                <a target="_blank" @click.stop="0" :href="$t('url.terms')">
                  {{ $t("payment.gdpr_terms_of_service_substring") }}
                </a>
                <a target="_blank" @click.stop="0" :href="$t('url.gdpr')">
                  {{ $t("payment.gdpr_read_more_substring") }}
                </a>
              </i18n>
            </label>
          </div>
          <template v-if="$v.gdprAccepted.$error">
            <p v-if="!$v.gdprAccepted.accept" class="help is-danger">{{ $t("auth.consent_required") }}</p>
          </template>
        </div>

        <template v-if="isMebleEvent">
          <!-- Privacy policy and terms of service consent -->
          <div class="field" ref="privacyPolicyField">
            <div class="control">
              <label class="checkbox">
                <checkbox class="checkbox-input" v-model="terms"></checkbox>
                <i18n
                  path="auth.terms_and_conditions"
                  tag="span"
                  class="consent-text unselectable"
                  :class="termsClass"
                  @click="terms = !terms"
                >
                  <a target="_blank" @click.stop="0" href="https://drive.google.com/file/d/1Adf8991va6X9TcyT8Dl05DRXGND8L0WV/view">{{
                    $t("auth.privacy_policy_substring")
                  }}</a>
                  <a target="_blank" @click.stop="0" :href="$t('url.terms')">{{ $t("auth.terms_of_service_substring") }}</a>
                </i18n>
              </label>
              <template v-if="$v.terms.$error">
                <p v-if="!$v.terms.accept" class="help is-danger">{{ $t("auth.consent_required") }}</p>
              </template>
            </div>
          </div>

          <!-- GDPR consent -->
          <div class="field mb-3" ref="gdprField">
            <div class="control">
              <label class="checkbox consents">
                <checkbox class="checkbox-input" v-model="gdpr"></checkbox>
                <i18n :path="gdprStringPath" tag="span" class="consent-text unselectable" :class="gdprClass" @click="gdpr = !gdpr">
                  <a target="_blank" @click.stop="0" :href="$t('custom.meble_terms_url')">
                    {{ $t("auth.read_more") }}
                  </a>
                </i18n>
              </label>
              <template v-if="$v.gdpr.$error">
                <p v-if="!$v.gdpr.accept" class="help is-danger">{{ $t("auth.consent_required") }}</p>
              </template>
            </div>
          </div>
        </template>
      </div>
    </template>

    <template #buttons>
      <button class="button is-light is-rounded" @click="previous">{{ $t("payment.previous") }}</button>
      <button class="button is-primary is-rounded ml-auto" @click="next">{{ $t("payment.next") }}</button>
    </template>
  </purchase-step>
</template>

<script>
import { mapMutations, mapGetters, mapState, mapActions } from "vuex";
import PurchaseStep from "./PurchaseStep";
import CustomRegisterForm from "@/web/components/forms/CustomRegisterForm";
import FormBuilderConstants from "../../views/dev/formbuilder/form-builder-constants";
import { required } from "vuelidate/lib/validators";
import Checkbox from "@/shared/components/form/Checkbox";

export default {
  name: "PurchaseSecondStep",

  components: {
    CustomRegisterForm,
    PurchaseStep,
    Checkbox,
  },

  data() {
    return {
      isInvalid: false,
      ticketForms: {},
      additionalForms: {},
      //Guest tickets
      gdprAccepted: false,
      //Unauthorized user
      terms: false,
      gdpr: false,
    };
  },

  validations() {
    const validations = {};
    if (this.isMebleEvent) {
      validations.gdprAccepted = this.gdprVisible ? { required: value => !!value } : {};
      validations.gdpr = { required: value => !!value };
      validations.terms = { required: value => !!value };
    }
    return validations;
  },

  computed: {
    ...mapState(["eventId"]),
    ...mapGetters(["customTicketForms"]),
    ...mapState("payment", ["guestsPersonalForms"]),
    ...mapGetters("payment", ["gdprConsentRequired"]),
    ...mapGetters("eventTickets", { getTicketById: "getById" }),
    ...mapGetters("locales", ["currentLocale"]),

    gdprVisible() {
      return this.gdprConsentRequired;
    },

    isPismEvent() {
      return this.eventId === 1312;
    },

    isMebleEvent() {
      return this.eventId === 1343 || this.eventId === 245;
    },

    isProExpertEvent() {
      return this.eventId === 1419;
    },

    isXEvent() {
      return this.eventId === 1444;
    },

    isDtsEvent() {
      return this.eventId === 1411;
    },

    gdprStringPath() {
      return "custom.meble_terms";
    },

    gdprClass() {
      if (this.$v.$dirty && !this.$v.gdpr.required) {
        return "has-text-danger";
      } else {
        return "";
      }
    },

    termsClass() {
      if (this.$v.$dirty && !this.$v.terms.required) {
        return "has-text-danger";
      } else {
        return "";
      }
    },

    gdprConsentClass() {
      if (this.gdprVisible && this.$v.$dirty && !this.$v.gdprAccepted.required) {
        return "has-text-danger";
      } else {
        return "";
      }
    },
  },

  methods: {
    ...mapActions("payment", ["setMultipleAdditionalForms"]),

    updateAdditionalForm(userEmail, formData) {
      this.additionalForms[userEmail] = formData;
    },

    validation(invalid) {
      this.isInvalid = invalid;
    },

    next() {
      this.$v.$touch();

      let areFormsValid = !this.$v.$invalid;
      const forms = this.$refs.customRegisterForm;
      if (forms && forms.length) {
        forms.forEach(form => form.touch());
        areFormsValid =
          areFormsValid &&
          forms.reduce((isValid, form) => {
            return isValid && form.isValid();
          }, true);
      }
      if (areFormsValid) {
        this.setMultipleAdditionalForms(this.additionalForms);
        this.$emit("next");
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      } else {
        this.$nextTick().then(() => this.focusInvalidInput());
      }
    },

    focusInvalidInput() {
      const input = this.$el.querySelector("has-text-danger");
      if (input) {
        input.focus();
      }
    },

    previous() {
      this.$emit("previous");
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },

  watch: {
    customTicketForms: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          Object.values(newValue).forEach(customForm => {
            this.$mergeCustomTranslations(customForm[FormBuilderConstants.TRANSLATIONS], FormBuilderConstants.TRANSLATIONS_NAMESPACE);
          });
        }
      },
    },

    guestsPersonalForms: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          let ticketForms = [];
          Object.entries(newValue).forEach(([key, value]) => {
            if (value && value.length) {
              value.forEach(personalForm => {
                let obj = {
                  ticketId: key,
                };
                obj.customForm = this.customTicketForms[key];
                obj.personalForm = personalForm;
                if (obj.customForm) {
                  ticketForms = [...ticketForms, obj];
                }
              });
            }
          });
          this.ticketForms = ticketForms;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.consents {
  font-size: 15px;
  white-space: pre-line;
}
</style>
