<template>
  <article class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <div class="columns is-vcentered is-variable is-7">
        <div class="column is-half">
          <div class="pism-title">{{ $t("custom.pism_about_title") }}</div>
          <div class="pism-subtitle">{{ $t("custom.pism_about_subtitle") }}</div>
          <div class="pism-description">{{ $t("custom.pism_about_description") }}</div>
          <div class="pism-buttons-container">
            <button class="button is-primary" @click="scrollToModule('#module-3')">{{ $t("custom.pism_about_button_1") }}</button>
            <button class="button is-primary is-outlined" @click="scrollToModule('#module-0')" v-if="isAuthorized">
              {{ $t("custom.pism_about_button_2") }}
            </button>
          </div>
        </div>
        <div class="column is-half">
          <div class="pism-image">
            <div class="pism-review">
              <div class="pism-review-text">{{ $t("custom.pism_about_review_text") }}</div>
              <div class="pism-author-container">
                <profile-picture class="is-36x36" :picture="avatarPicture" initials="SD"></profile-picture>
                <div>
                  <div class="pism-review-author-name">{{ $t("custom.pism_about_review_author_name") }}</div>
                  <div class="pism-review-author-position">{{ $t("custom.pism_about_review_author_position") }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import Separator from "@/web/components/shared/Separator";
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import { navScrollTo } from "../../../shared/utils/nav-scroll-to";
import { mapGetters, mapState } from "vuex";

export default {
  name: "PismAboutCustomComponent",
  mixins: [LpConfigMixin],
  components: { ProfilePicture, Separator },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  methods: {
    scrollToModule(hash) {
      navScrollTo(hash, "smooth");
    },
  },

  computed: {
    ...mapGetters("auth", ["isAuthorized"]),

    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    avatarPicture() {
      return {
        file_thumbnail_200_url: "https://zapodaj.net/images/a92ee5bbbbf39.jpg",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.pism-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 109%;
  color: var(--primary-color);
}

.pism-subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 109%;
  margin-top: 6px;
  color: var(--text-color-primary);
}

.pism-description {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 155%;
  color: var(--text-color-primary);
  margin-top: 12px;
}

.pism-buttons-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  column-gap: 22px;

  .button {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    width: 173px;

    &.is-primary {
      color: black;
    }

    &.is-outlined {
      color: white;
      border-color: white;

      &:hover {
        border-color: var(--primary-color);
        background-color: var(--primary-color-hover);
      }
    }
  }
}

.pism-image {
  position: relative;
  height: 309px;
  border-radius: 4px;
  background-image: url(~@/assets/custom/pism_module_1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .pism-review {
    position: absolute;
    bottom: -34px;
    right: 23px;
    background: #ffffff;
    border-radius: 4px;
    padding: 13px 15px;
    max-width: 239px;

    .pism-review-text {
      font-style: italic;
      font-weight: 300;
      font-size: 10px;
      line-height: 155%;
    }

    .pism-author-container {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      column-gap: 12px;

      .pism-review-author-name {
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 155%;
      }

      .pism-review-author-position {
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 15.5px;
      }
    }
  }
}
</style>
