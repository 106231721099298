<template>
  <form @input="input" @submit.prevent>
    <div class="columns">
      <div class="column is-half">
        <div class="field">
          <label class="label has-text-primary" :style="{ color: customColor + ' !important' }">{{
            $t("auth.auth_firstname_placeholder")
          }}</label>
          <div class="control">
            <input
              class="input"
              type="text"
              :placeholder="$t('auth.auth_firstname_placeholder')"
              :class="{ 'is-danger': $v.firstName.$error }"
              v-model.trim="$v.firstName.$model"
            />
          </div>
          <template v-if="$v.firstName.$error">
            <p v-if="!$v.firstName.required" class="help is-danger">{{ $t("auth.error_first_name") }}</p>
          </template>
        </div>
      </div>

      <div class="column is-half">
        <div class="field">
          <label class="label has-text-primary" :style="{ color: customColor + ' !important' }">{{
            $t("auth.auth_lastname_placeholder")
          }}</label>
          <div class="control">
            <input
              class="input"
              type="text"
              :placeholder="$t('auth.auth_lastname_placeholder')"
              :class="{ 'is-danger': $v.lastName.$error }"
              v-model.trim="$v.lastName.$model"
            />
          </div>
          <template v-if="$v.lastName.$error">
            <p v-if="!$v.lastName.required" class="help is-danger">{{ $t("auth.error_last_name") }}</p>
          </template>
        </div>
      </div>
    </div>

    <!--    <div>-->
    <!--      To register for the event, simply fill out the registration form by May 18th.</br>-->
    <!--      Please indicate whether you will be accompanied by your partner and children, and provide the age of any children attending.</br>-->
    <!--      After registering for the event, you will receive a special QR code by email that entitles you to enter the picnic.</br>-->
    <!--      </br>-->
    <!--      </br>-->
    <!--      We have also prepared a special mobile app in which you can e.g. sign up for a volleyball tournament, post a photo of you having fun, share your impressions on the feed wall, text with other participants</br>-->
    <!--      find out how to get to Partynice and always have the agenda of the event with you.</br>-->
    <!--      To take advantage of these opportunities you should download the application and set up an account using the same company email address as in registration form.</br>-->
    <!--    </div>-->

    <div class="columns">
      <div class="column is-half">
        <div class="field">
          <label class="label has-text-primary" :style="{ color: customColor + ' !important' }">{{
            isDolbyEvent ? $t("custom.dolby_business_email") : $t("auth.email")
          }}</label>
          <div class="control">
            <input
              class="input"
              type="email"
              :placeholder="isDolbyEvent ? $t('custom.dolby_business_email') : $t('auth.email')"
              v-model.trim="email"
              :disabled="emailDisabled"
              :class="{ 'is-danger': $v.email.$error }"
            />
            <p class="custom-email-text" v-if="isPedEvent">{{ $t("custom.ped_ticket_first_step_under_email") }}</p>
            <template v-if="$v.email.$error">
              <p v-if="!$v.email.required" class="help is-danger">{{ $t("common.field_required_error") }}</p>
              <p v-if="!$v.email.email" class="help is-danger">{{ $t("auth.error_email") }}</p>
              <p v-if="!$v.email.alreadyHasTicket" class="help is-danger">{{ $t("payment.already_has_a_ticket_error") }}</p>
              <p v-if="!$v.email.notOnWhitelist" class="help is-danger">{{ $t("payment.not_on_whitelist_error") }}</p>
            </template>
            <template v-if="$v.personalForms.$error">
              <p v-if="!$v.personalForms.isEmailUnique" class="help is-danger">{{ $t("payment.email_all_unique_error") }}</p>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- This is me checkbox -->
    <div class="field" v-if="!(isSmakkiEvent || isDolbyEvent)">
      <div class="control">
        <label class="checkbox is-size-7 mb-1">
          <checkbox class="checkbox-input" v-model="$v.thisIsMe.$model"></checkbox>
          <i18n path="payment.this_is_me_checkbox" tag="span" class="consents" @click="$v.thisIsMe.$model = !$v.thisIsMe.$model"> </i18n>
        </label>
      </div>
      <template v-if="$v.personalForms.$error">
        <p v-if="!$v.personalForms.isMyTicketSelected" class="help is-danger">{{ $t("payment.my_ticket_not_selected_error") }}</p>
      </template>
    </div>
  </form>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import Constants from "@/web/constants";
import Checkbox from "@/shared/components/form/Checkbox";
import { mapGetters, mapState } from "vuex";

export default {
  name: "PersonalDetailsForm",
  components: { Checkbox },
  props: {
    value: {
      type: Object,
      required: false,
    },
    emailDisabled: {
      type: Boolean,
      required: true,
    },
    personalForms: {
      type: Object,
      required: false,
    },
    emailErrors: {
      type: Array,
      required: false,
    },
    isPedEvent: {
      type: Boolean,
      required: false,
    },
    customColor: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      thisIsMe: false,
    };
  },

  validations() {
    const validations = {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      email: {
        email: value => email(value && value.toLowerCase().replace(/\s/g, "")),
      },
      personalForms: {
        isEmailUnique,
        isMyTicketSelected,
      },
      thisIsMe: {},
    };
    if (!this.emailDisabled) {
      validations.email = {
        required,
        email: value => email(value && value.toLowerCase()),
        alreadyHasTicket: value => alreadyHasTicket(value, this.emailErrors),
        notOnWhitelist: value => notOnWhitelist(value, this.emailErrors),
      };
    }
    return validations;
  },

  computed: {
    ...mapGetters("currentUser", ["currentUser"]),
    ...mapState(["eventId"]),

    isSmakkiEvent() {
      return this.eventId === 1251;
    },

    isDolbyEvent() {
      return this.eventId === 1318;
    },
  },

  methods: {
    input() {
      const formData = { ...this.$data };
      if (this.$data.thisIsMe) {
        this.$emit("clear-this-is-me-flags");
      }
      this.$emit("input", formData);
    },

    touch() {
      this.$v.$touch();
    },

    focusInvalidInput() {
      this.$nextTick().then(() => {
        const input = this.$el.querySelector("input.is-danger");
        if (input) {
          input.focus();
        }
      });
    },

    isValid() {
      return !this.$v.$anyError;
    },
  },

  watch: {
    thisIsMe: {
      handler(value) {
        this.input();
      },
    },
    value: {
      handler(value) {
        // Copy only known properties in $data
        // eslint-disable-next-line prettier/prettier
        Object.keys(this.$data).forEach(key => {
          if (key === "thisIsMe") {
            this.$data[key] = valueOrDefault(value && value[key], false);
          } else if (key === "email") {
            this.$data[key] = valueOrDefault(value && value[key] && value[key].toLowerCase().replace(/\s/g, ""));
          } else {
            this.$data[key] = valueOrDefault(value && value[key]);
          }
        });
      },
      immediate: true,
    },
    "$v.$invalid": {
      handler(invalid) {
        this.$emit("validation", invalid);
      },
      immediate: true,
    },
  },
};

function valueOrDefault(value, defaultValue = null) {
  return typeof value === "undefined" ? defaultValue : value;
}

function isEmailUnique(value) {
  let filteredValues = Object.values(value)
    .reduce((acc, array) => {
      acc = [...acc, ...array.map(form => form.email)];
      return acc;
    }, [])
    .filter(mail => mail === this.email);
  return filteredValues.length < 2;
}

function isMyTicketSelected(value) {
  let filteredValues = Object.values(value)
    .reduce((acc, array) => {
      acc = [...acc, ...array.map(form => form.thisIsMe)];
      return acc;
    }, [])
    .filter(thisIsMe => thisIsMe);
  return filteredValues.length > 0;
}

function alreadyHasTicket(value, emailErrors) {
  if (!emailErrors) {
    return true;
  } else {
    return !emailErrors.some(
      emailError => emailError.email === value && emailError.error === Constants.PAYMENT_INIT_EMAIL_ALREADY_HAS_AN_TICKET_ERROR_CODE
    );
  }
}

function notOnWhitelist(value, emailErrors) {
  if (!emailErrors) {
    return true;
  } else {
    return !emailErrors.some(
      emailError => emailError.email === value && emailError.error === Constants.PAYMENT_INIT_EMAIL_NOT_ON_WHITELIST_ERROR_CODE
    );
  }
}
</script>

<style lang="scss">
.consents {
  font-size: 15px;
  white-space: pre-line;
}

.custom-email-text {
  color: #d3d3d3;
  padding-top: 10px;
  font-size: 14px;
}
</style>
