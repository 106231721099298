<template>
  <paginated-content :items="exhibitors" :perPage="pageSize">
    <template v-slot="{ displayItems }">
      <div class="columns">
        <div v-for="(exhibitor, index) in displayItems" :key="index" class="column" :class="columnClass">
          <exhibitor-list-item-view
            :exhibitors="exhibitors"
            :data="exhibitor"
            :is-authorized="isAuthorized"
            :lp-mode="lpMode"
            :inbox-enabled="inboxEnabled"
            @tag-click="$emit('tag-click', $event)"
          ></exhibitor-list-item-view>
        </div>
      </div>
    </template>
  </paginated-content>
</template>

<script>
import PaginatedContent from "@/shared/components/common/pagination/PaginatedContent";
import ExhibitorListItemView from "@/shared/components/exhibitors/ExhibitorListItemView";

export default {
  name: "ExhibitorsPagerView",

  props: ["exhibitors", "pageSize", "lpMode", "isAuthorized", "inboxEnabled", "fluidNumberOfColumns"],

  components: {
    PaginatedContent,
    ExhibitorListItemView,
  },

  computed: {
    columnClass() {
      if (this.fluidNumberOfColumns) {
        return "is-one-quarter-fullhd is-half-tablet";
      } else {
        return "is-half";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.columns {
  flex-wrap: wrap;
}
</style>
