<template>
  <div class="event-date">
    <p class="is-size-5 has-text-weight-bold" :style="textColor">
      <agenda-icon :style="textColor" class="agenda-icon mr-1"></agenda-icon>
      {{ formattedTime }}
    </p>
  </div>
</template>

<script>
import AgendaIcon from "@/assets/icon_agenda.svg";
import { composeDateRange } from "@/shared/utils";

export default {
  name: "EventDate",

  components: {
    AgendaIcon,
  },

  props: ["event", "textColor"],

  computed: {
    formattedTime() {
      const { start_date, end_date, timezone } = this.event;
      return composeDateRange(start_date, end_date, timezone);
    },
  },
};
</script>

<style lang="scss" scoped>
.agenda-icon {
  margin-top: -6px;
  vertical-align: middle;
}
</style>
