<template>
  <article class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <div class="columns is-vcentered is-variable is-7">
        <div class="column is-half">
          <div class="unity-title">{{ $t("custom.unity_about_title") }}</div>
          <div class="unity-subtitle">{{ $t("custom.unity_about_subtitle") }}</div>
          <div class="unity-description">{{ $t("custom.unity_about_description") }}</div>
          <div class="unity-buttons-container">
            <button class="button is-primary" @click="scrollToSpeakersModule" v-if="speakersButtonsVisible">
              {{ $t("custom.unity_about_button_1") }}
            </button>
            <button class="button is-primary" @click="scrollToMobileAppModule" v-if="downloadAppButtonVisible">
              {{ $t("custom.unity_about_button_2") }}
            </button>
          </div>
        </div>
        <div class="column is-half">
          <div class="unity-image">
            <div class="unity-review">
              <div class="unity-review-text">{{ $t("custom.unity_about_review_text") }}</div>
              <div class="unity-author-container">
                <!--                <profile-picture class="is-36x36" :picture="avatarPicture" initials="SD"></profile-picture>-->
                <div>
                  <div class="unity-review-author-name">{{ $t("custom.unity_about_review_author_name") }}</div>
                  <div class="unity-review-author-position">{{ $t("custom.unity_about_review_author_position") }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import Separator from "@/web/components/shared/Separator";
import { mapGetters } from "vuex";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import { navScrollTo } from "@/shared/utils";
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";

export default {
  name: "UnityAboutCustomComponent",

  mixins: [LpConfigMixin],
  components: {
    Separator,
    // ProfilePicture
  },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  methods: {
    scrollToMobileAppModule() {
      this.scrollToModule(`#module-${this.mobileAppModules[0].id}`);
    },

    scrollToSpeakersModule() {
      this.scrollToModule(`#module-${this.speakerModules[0].id}`);
    },

    scrollToModule(hash) {
      navScrollTo(hash, "smooth");
    },
  },

  computed: {
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapGetters(["mobileAppModules", "speakerModules"]),

    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    avatarPicture() {
      return {
        file_thumbnail_200_url: "https://zapodaj.net/images/a92ee5bbbbf39.jpg",
      };
    },

    downloadAppButtonVisible() {
      return this.mobileAppModules && this.mobileAppModules.length;
    },

    speakersButtonsVisible() {
      return this.speakerModules && this.speakerModules.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.unity-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 109%;
  color: var(--primary-color);
}

.unity-subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 109%;
  margin-top: 6px;
  color: var(--text-color-primary);
}

.unity-description {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 155%;
  color: var(--text-color-primary);
  margin-top: 12px;
}

.unity-buttons-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  column-gap: 22px;

  .button {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    width: 173px;
    min-height: 50px;

    &.is-outlined {
      color: white;
      border-color: white;

      &:hover {
        border-color: var(--primary-color);
        background-color: var(--primary-color-hover);
      }
    }
  }
}

.unity-image {
  position: relative;
  height: 309px;
  background-image: url(~@/assets/custom/unity/unity_1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .unity-review {
    position: absolute;
    bottom: -34px;
    right: 23px;
    background: var(--primary-color);
    padding: 13px 15px;
    max-width: 239px;

    .unity-review-text {
      font-style: italic;
      font-weight: 300;
      font-size: 10px;
      line-height: 155%;
      color: var(--primary-color-text);
    }

    .unity-author-container {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      column-gap: 12px;

      .unity-review-author-name {
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 155%;
        color: var(--primary-color-text);
      }

      .unity-review-author-position {
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 15.5px;
        color: var(--primary-color-text);
      }
    }
  }
}
</style>
