<template>
  <div @click="openSpeakerInfo()" class="speaker-list-item-container">
    <profile-card v-bind="{ picture, firstName, lastName, description, customClass }">
      <template v-if="showCountry && country" #title>
        <CountryList :countries="country" />
      </template>
      <template #action>
        <info-icon class="info-icon-color"></info-icon>
      </template>
    </profile-card>
  </div>
</template>

<script>
import InfoIcon from "@/assets/icons/info.svg";
import { mapMutations, mapState } from "vuex";
import ProfileCard from "@/web/components/shared/ProfileCard";
import CountryList from "@/web/components/profile/CountriesList";
import { WidgetViewTypes } from "@/widgets/shared/store/modules/widget-view";

export default {
  name: "SpeakerListItemView",

  props: ["data", "showCountry", "lpMode"],

  components: {
    ProfileCard,
    CountryList,
    InfoIcon,
  },

  computed: {
    ...mapState(["settings", "eventId"]),

    speaker() {
      return this.data;
    },

    firstName() {
      return this.speaker.first_name;
    },

    lastName() {
      return this.speaker.last_name;
    },

    picture() {
      return this.speaker.picture;
    },

    country() {
      return this.speaker.country;
    },

    customClass() {
      return "speakers-box-custom-color";
    },

    description() {
      const { company, position } = this.speaker;
      if (company && position) {
        return position + ", " + company;
      } else if (company) {
        return company;
      } else if (position) {
        return position;
      } else {
        return null;
      }
    },
  },

  methods: {
    ...mapMutations("widgetView", ["changeActiveView"]),

    openSpeakerInfo() {
      if (this.lpMode) {
        this.$root.openSpeakerModal({
          speaker: this.speaker,
          eventId: this.eventId,
        });
      } else {
        this.changeActiveView({
          type: WidgetViewTypes.SPEAKER_INFO,
          componentId: this.$root.componentId,
          data: {
            speaker: this.speaker,
            eventId: this.eventId,
          },
        });
      }
    },
  },
};
</script>
