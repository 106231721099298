<template>
  <form action="." method="POST" @submit.prevent="() => {}" @input="input">
    <div class="field" v-for="addon in eventAddonsToDisplay" :key="`event-addon-${addon.id}-order-${orderKey}`">
      <div class="control">
        <label class="checkbox is-size-7 mb-1">
          <checkbox class="checkbox-input" :value="$data[addon.id]" @input="updateValue(addon.id, !$data[addon.id])"></checkbox>
          <span @click="updateValue(addon.id, !$data[addon.id])" class="consents" v-html="addon.description"></span>
          <span class="is-primary">{{
            `(${isUpgrade2023Event ? "" : "+"}${$options.filters.currency(getAddonPrice(addon.price), {
              locale: "pl-PL",
              currency: addon.currency,
            })}${upgradeEventSuffix})`
          }}</span>
        </label>
      </div>
    </div>
  </form>
</template>

<script>
import Checkbox from "@/shared/components/form/Checkbox";
import { mapGetters, mapState } from "vuex";

export default {
  name: "EventAddonsForm",
  components: {
    Checkbox,
  },
  props: {
    eventTicket: {
      type: Object,
      required: true,
    },
    orderKey: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
    },
  },

  data() {
    let data = {};
    this.eventTicket.event_addons.forEach(addon => {
      data[addon.id] = false;
    });
    return data;
  },

  computed: {
    ...mapGetters(["eventTicketsVat"]),
    ...mapState(["eventId"]),

    eventAddons() {
      return this.eventTicket.event_addons;
    },

    eventAddonsToDisplay() {
      if (this.isEvolutions2024Event) {
        const orderedIds = [55, 48, 39, 40, 47, 41, 46, 45, 42, 43];
        return [...this.eventAddons].sort((a, b) => {
          return orderedIds.indexOf(a.id) - orderedIds.indexOf(b.id);
        });
      } else {
        return this.eventAddons;
      }
    },

    isEvolutions2024Event() {
      return this.eventId === 1509;
    },

    isUpgrade2023Event() {
      return this.eventId === 1355;
    },

    isArawEvent() {
      return this.eventId === 1509;
    },

    upgradeEventSuffix() {
      if (this.isUpgrade2023Event) {
        return ` + ${this.$t("payment.vat")}`;
      } else {
        return "";
      }
    },
  },

  methods: {
    updateValue(addonId, value) {
      this.$data[addonId] = value;
      this.input();
    },
    input() {
      this.$emit("input", this.getFormData());
    },

    getFormData() {
      return this.eventAddons.reduce((acc, val) => {
        acc[val.id] = this.$data[val.id];
        return acc;
      }, {});
    },

    getAddonPrice(price) {
      let priceWithCurrency = price * 0.01;
      if (this.isUpgrade2023Event) {
        let factor = 1.0 + this.eventTicketsVat;
        return priceWithCurrency / factor;
      } else {
        return priceWithCurrency;
      }
    },
  },

  filters: {
    currency(value, { locale, currency }) {
      const opts = { style: "currency", currency: currency, minimumFractionDigits: 2 };
      const formatter = new Intl.NumberFormat(locale, opts);
      const formatted = formatter.format(Number(value));
      return formatted.replace(/,00/, "");
    },
  },
};
</script>

<style scoped></style>
